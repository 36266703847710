import { Grid, Typography, Card, CardContent } from '@mui/material';
import who from "../../../assets/undraw_questions_re_1fy7.svg"

export default function CardInfo1() {

    return (
        <Card className="card">
            <CardContent>
                <Grid container spacing={3} alignItems="center">
                    <Grid item sm={12} md={6} >

                        <img alt="who" src={who} style={{ width: "50%" }} />
                    </Grid>
                    <Grid item xs className="card-text">
                        <Typography> Are you a registered apprentice looking to get ahead and cover college content while you wait for your block release?   </Typography>

                        <Typography style={{ marginTop: "20px" }} >Are you currently struggling with course content on phases 2, 4 or 6 ?   </Typography>

                        <Typography style={{ marginTop: "20px" }} >Do you have a referral exam that you require help with?  </Typography>

                        <Typography style={{ marginTop: "20px" }} >Our tutors are here to help and are only a click away!   </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}