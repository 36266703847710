import {
    Card, CardContent, CardHeader,
    Box, Typography
} from "@mui/material";
import React, { Suspense } from 'react'
import bronze from "../../assets/packages/Bronze.jpg"
import silver from "../../assets/packages/Silver.jpg"
import gold from "../../assets/packages/Gold.jpg"
import green from "../../assets/packages/Green.jpg"
import styles from "./styles.css"
// import {Img} from 'react-image'

function OurServices() {

    return (
        <div className={styles}>

            <Box sx={{ marginTop: { xs: '0px', sm: "70px" } }}>
                <Card style={{ width: "85%", align: "center" }} className="card">
                    <CardContent>
                        <CardHeader title={"Our services"} style={{ textAlign: "left" }}>
                        </CardHeader>
                        <div style={{ textAlign: "center" }}>
                            <Typography gutterBottom>If you are waiting for Block release (College placement on Phases 2,4 and 6) and anxious to get ahead why not try one of our Packages.
                            </Typography>
                            <Typography gutterBottom>All grinds are done remotely via Zoom or Microsoft Teams.</Typography>
                            <Typography gutterBottom>Our experienced instructors are ready and waiting. </Typography>
                            <Typography gutterBottom>(Class group special offers apply for Phases 4 and 6 where there is a Maximum of 6 Students. This is set at €300 per apprentice for 6 x 1.5 hour grinds)
                            </Typography>
                        </div>
                    </CardContent>

                </Card>

                <Card className="card">
                    <CardHeader title={"Bronze"} style={{ textAlign: "center", color: "#CD7F32", backgroundColor: "light-grey" }}>
                    </CardHeader>
                    <Suspense>
                        <img alt="bronze" src={bronze} id="bronze" className="slide-frame"
                            loading="eager"
                            onClick={() => { document.getElementById('bronze')?.requestFullscreen() }} />
                    </Suspense>
                </Card>
                <Card className="card">
                    <CardHeader title={"Silver"} style={{ textAlign: "center", color: "silver" }}>
                    </CardHeader>
                    <img alt="silver" src={silver} className="slide-frame"
                        loading="eager"
                        onClick={() => { document.getElementById('bronze')?.requestFullscreen() }} />
                </Card>
                <Card className="card">
                    <CardHeader title={"Gold"} style={{ textAlign: "center", color: "gold" }}>
                    </CardHeader>
                    <img alt="gold" id="gold" src={gold} className="slide-frame"
                        loading="eager"
                        onClick={() => { document.getElementById('gold')?.requestFullscreen() }} />
                </Card>
                <Card className="card">
                    <CardHeader title={"Green"} style={{ textAlign: "center", color: "green" }}>
                    </CardHeader>
                    <img alt="green" id="green" src={green} className="slide-frame"
                        loading="eager"
                        onClick={() => { document.getElementById('green')?.requestFullscreen() }} />
                    <stripe-buy-button
                        buy-button-id="buy_btn_1MsWYwJZntCnzF7nqjJTjZVb"
                        publishable-key="pk_test_51MsWOUJZntCnzF7nVqWReNIBGNiUQXiOhWYitVkOf1R0x6TRe1jwrZfGZSxzuAfL58GnGq56yiQKqF14pGeGQ7c600ZrIgNz4y"
                    >
                    </stripe-buy-button>
                </Card>
            </Box>

        </div>
    )
}
export default OurServices