import { Typography, Card, CardContent} from '@mui/material';

export default function Companies(){

    return (
<Card className="card">
    <CardContent>
        How do you get started?!
        <Typography>
            Please fill in the form below and enter your details so we can access your level and line you up with one of our tutors.
        </Typography>

    </CardContent>

</Card>)
}