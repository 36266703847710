//jsx material ui component for the "About Us" page with cards for 5 sections
import { Card, CardContent, CardHeader, Grid} from "@mui/material";
import {getAllFromCollection} from "../../firebase"

import React  from 'react';


function Dashboard() {
  console.log(getAllFromCollection(""))
  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container >
        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ width: "85%" }}>

            <CardContent>
              <CardHeader title={"Dashboard"} style={{ marginTop: "40px", textAlign: "left" }}>
               Dashboard
              </CardHeader>
              <Grid container justify="space-between">  
              </Grid>
            </CardContent>

          </Card>
        </Grid>
      </Grid>
    </div>)

}

export default Dashboard
