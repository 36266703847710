import { useState } from "react";
import plumbingMath from "../../../assets/plumbing-maths.jpg"
import {
    MenuItem, TextareaAutosize, InputLabel,
    Typography, Select, TextField, Card, CardContent, Button, FormControl
} from '@mui/material';
import { sendEmailWithDetails } from '../../../services/home-services'
import LoadingButton from '@mui/lab/LoadingButton';
import '../style.css'
import React  from 'react';
import {addDocument} from "../../../firebase"
export default function Forum() {
    var defaultFormValues = {
        name: "",
        email: "",
        mobile: "",
        trade: "",
        grindPreference: "",
        solasReg: "",

    }


    const [application, setApplication] = useState(defaultFormValues);
    const [applicationSendStatus, setApplicationSendStatus] = useState(false);
    const [applicationResponse, setApplicationResponse] = useState('unsent');

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setApplication(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setApplicationSendStatus(true);
        try {
            addDocument(application)
            await sendEmailWithDetails(application);
            setApplication(defaultFormValues);
            setApplicationResponse("success");
        }
        catch (error) {
            setApplicationResponse("error");
        }

    }
    const tradeOptions = [
        { value: "motorMechanics", name: "Motor Mechanics " },
        { value: "plumbing", name: "Plumbing" },
        { value: "carpentryAndJoining", name: "Carpentry and Joinery " },
        { value: "metalFabrication", name: "Metal Fabrication " },
        { value: "brickAndStoneLaying", name: "Brick & Stone Laying" },
        { value: "pipeFitting", name: "Pipe Fitting" },
        { value: "electricalTrade", name: "Electrical Trade" },
    ]
    const grindOptions = [
        { value: "evening", name: "Evening Grinds" },

        { value: "weekend", name: "Weekend Grinds" },
        { value: "both", name: "Both" }
    ]

    function TradeDropDown() {
        return (
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel
                    id="trade">Trade</InputLabel>
                <Select
                    label="Trade"
                    onChange={handleChange}
                    value={application.trade || ""}
                    name="trade"
                >{
                        tradeOptions.map(x => {
                            return <MenuItem value={x.value} key={x.value}>{x.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        )
    }

    function GrindOptions() {
        return (
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel
                    id="grindPreference">Grind Preference</InputLabel>
                <Select
                    label="grindPreference"
                    onChange={handleChange}
                    value={application.grindPreference || ""}
                    name="grindPreference"
                >{
                        grindOptions.map(x => {
                            return <MenuItem value={x.value} key={x.value}>{x.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        )
    }


    function ServerResponse() {
        if (applicationResponse === "error") {
            return (<Button variant="outlined" color="error">There was an error, please send an email to {process.env.REACT_APP_APPLICATION_EMAIL}</Button>)
        }
        else if (applicationResponse === "success") {
            return (<Button variant="outlined" color="success">Sent successfully, we'll be in touch shortly.</Button>)
        }
        return (
            <LoadingButton type="submit" variant="outlined" loading={applicationSendStatus}>
                Submit </LoadingButton>)

    }



    return (<form className="form" onSubmit={handleSubmit} style={{ backgroundImage: `url(${plumbingMath})` }}>

        <div className="formCard">

            <Card sx={{ minWidth: 380 }} style={{ maxWidth: "80%" }}>
                Details form :
                <CardContent>
                    <div className='formElements'>
                        <TextField
                            style={{ width: "300px", margin: "5px" }}
                            type="text"
                            id="first-name"
                            label="Name"
                            name="name"
                            variant="outlined"
                            value={application.name || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            style={{ width: "300px", margin: "5px" }}
                            type="email"
                            id="email"
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={application.email || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            style={{ width: "300px", margin: "5px" }}
                            type="text"
                            label="Mobile Phone Number"
                            name="mobile"
                            variant="outlined"
                            value={application.mobile || ""}
                            onChange={handleChange}
                        />

                        <TradeDropDown />
                        <GrindOptions />
                        <TextareaAutosize
                            style={{ width: "300px", height: "200px", margin: "5px" }}
                            type="text"
                            name="help"
                            placeholder="How can we help you ?"
                            variant="outlined"
                            value={application.help || ""}
                            onChange={handleChange}
                        /></div>
                    <Typography>
                        All info linked back to this email address: <a href="mailto:tradegrinds2022@gmail.com">tradegrinds2022@gmail.com</a>
                    </Typography>
                    <ServerResponse />
                </CardContent>
            </Card>
        </div>
    </form>
    )
}