import { Card, CardContent, CardHeader, Box } from "@mui/material";
import React  from 'react';

import underConstruction from "../../assets/undraw_under_construction_-46-pa.svg"
function Testimonials(){
    return (

        <div>
            <Box>
            <Card >
                <CardContent>
                    <CardHeader title={"How it works page - Currently under construction"} style={{ marginTop: "40px", textAlign: "left" }}>
                    </CardHeader>
                    <Box
                    component="img"
                    alt="under construction tag"
                    src={underConstruction}
                ></Box>
                </CardContent>

            </Card>
            </Box>
        </div>)
}

export default Testimonials