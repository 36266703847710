import './App.css';
import NavBar from './components/navbar'
import React from "react";
import Home from './views/home'
import HowItWorks from './views/how-it-works'
import OurServices from './views/our-services'
import Testimonials from './views/testimonials'
import AboutUs from './views/about-us'
import Footer from "./components/footer";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {BrowserRouter, Routes,Route} from "react-router-dom";

import Login from "./views/login"
import Register from "./views/register"
import PrivateRoutes from './utils/PrivateRoutes';
import Dashboard  from './views/dashboard'

function App() {


  const theme = createTheme({
    palette: {
      // primary: {
      //   main: grey[700],
      // },
      secondary: {
        main: '#f44336',
      },
    },
  });
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <header className="App-header">
            <NavBar />
            <Routes>
              <Route element={<PrivateRoutes/>}>
                  <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/our-services" element={<OurServices />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </header>
        </div>
      </ThemeProvider>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
