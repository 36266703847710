import { Grid, Typography, Card, CardContent } from '@mui/material';
import why from "../../../assets/undraw_professor_re_mj1s.svg"


export default function CardInfo2() {

    return (
        <Card className="card">
            <CardContent>
                <Grid container spacing={3} alignItems="center">
                    <Grid item sm={12} md={6} order={{ xs: 2, md: 1 }} className="card-text-area">
                        <Typography>One on one or group classes available ( Max. Of 6 ) </Typography>
                        <Typography style={{ marginTop: "20px" }}>Online classes covering course content from experienced instructors/tutors.</Typography>
                        <Typography style={{ marginTop: "20px" }}>Access course content and revision notes at ease which will benefit you when you get your college placement.</Typography>
                        <Typography style={{ marginTop: "20px" }}>Fill out the query form below and we will be in touch!!</Typography>
                    </Grid>
                    <Grid item sm={12} md={6} order={{ xs: 1, md: 2 }}>
                        <img alt="why" src={why} style={{ width: "50%" }} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}