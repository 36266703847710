import React  from 'react';


var year = new Date().getFullYear();
function AboutUs() {

    return (
        <div className="footer" style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
            flexDirection: "column"
        }}>
            <p>©  Trade Grinds -  {year}  | All rights Reserved</p>
            <br></br>
            <p>Trade Grinds is an independent training company with no affiliation to any educational body</p>
        </div>
    )
}
export default AboutUs;