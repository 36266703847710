import React from 'react';
import {
    AppBar, Box, Toolbar, LinearProgress, Button, List, ListItemButton,
    ListItemText, IconButton, Typography, Collapse
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../../firebase";
import './style.css'

const links = [
    { name: "Home", path: "/home" },
    { name: "About Us", path: "/about-us" },
    { name: "How It Works", path: "/how-it-works" },
    { name: "Our Service", path: "/our-services" },
    { name: "Testimonials", path: "/testimonials" },
]

function NavBar() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [dropDown, setDropDown] = useState(true)
    const [user, loading] = useAuthState(auth);

    function NavButtons() {
        return links.map(({ name, path }, index) => (
            selectedIndex ===
                index ? <Link to={path} key={path + index}><Button key={path} style={{ color: "white", textDecoration: 'none' }} onClick={() => setSelectedIndex(index)} variant="outlined">{name}</Button></Link> :
                <Link to={path} key={path} style={{ textDecoration: 'none' }}><Button key={path} style={{ color: "white", textDecoration: 'none' }} onClick={() => setSelectedIndex(index)}>{name}</Button></Link>)
        )
    }

    function MobileNavItems() {
        return links.map(({ name, path }, index) => (
            <Link key={index} to={path} className="navigation" style={{ textDecoration: 'none', color: "white" }}>
                <ListItemButton
                    selected={selectedIndex === index}
                    onClick={() => setSelectedIndex(index)}

                >
                    <ListItemText primary={name} > </ListItemText>
                </ListItemButton>
            </Link>

        )
        )
    }

    function LoadingBar() {
        if (loading) {
            return (<LinearProgress />)
        }
        return (<div></div>)
    }

    function Profile() {
        if (user) {
            return (<div style={{ fontSize: "14px" }}>
                <div style={{display:'flex'}}>
                <p> {user.email}</p>
                <Button style={{ color: "white", textDecoration: 'none' }} 
                onClick={() => logout()} variant="outlined">Logout</Button>
                </div>
            </div>)
        }
        return (
            <Button style={{ color: "white", textDecoration: 'none' }} onClick={() => navigate("/login")} variant="outlined">Login</Button>)
    }
    return (<Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" sx={{ display: { xs: 'none', sm: 'flex' } }} style={{ position: 'fixed' }}>
            <Toolbar style={{ justifyContent: "space-between" }} >
                <section>
                    <NavButtons />
                </section>
                <Profile />
            </Toolbar>
            <LoadingBar />
        </AppBar>
        <AppBar position="static" sx={{ display: { xs: 'flex', sm: 'none' } }} >

            <Toolbar >
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => setDropDown(!dropDown)}
                >
                    <MenuIcon />

                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Trade Grinds
                </Typography>
            </Toolbar>
            <Collapse in={dropDown} >
                <List component="nav"  >
                    <MobileNavItems />
                </List>
            </Collapse>
        </AppBar>
    </Box>)
}

export default NavBar;