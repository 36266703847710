import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword,
    createUserWithEmailAndPassword, sendPasswordResetEmail, signOut
} from "firebase/auth";
import { getFirestore, query, getDocs, collection, where, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA-IdPDfypijv42DZlzdehUcXpwuCzZrlg",
    authDomain: "irish-trade-math.firebaseapp.com",
    projectId: "irish-trade-math",
    storageBucket: "irish-trade-math.appspot.com",
    messagingSenderId: "1096305484303",
    appId: "1:1096305484303:web:c54b7312bbcb8e9c04b97a",
    measurementId: "G-XS93K6ED3T"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const addDocument = async(value) => {
    try {
        await addDoc(collection(db, "applications"), {
            value
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
  
}


const getAllFromCollection = async(request) => {
    try {
        const querResult =  await query(collection(db, "applications"));
        const docs = await getDocs(querResult);
        docs.forEach((doc) => {
            console.log(JSON.stringify(doc.data()));
          
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
  
}




const logout = () => {
    signOut(auth);
};
export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    addDocument,
    getAllFromCollection
};