import { Box, Grid, } from '@mui/material';
// import ATU from "../../../assets/companys/ATU.jpg"
// import ETB from "../../../assets/companys/ETB.webp"
// import TU from "../../../assets/companys/GE.png"
// import GE from "../../../assets/companys/KC.jpg"
// import KC from "../../../assets/companys/Solas.jpg"
// import Solas from "../../../assets/companys/TU.png"
import plans from "../../../assets/workers-planning.png";
import { styled } from '@mui/material/styles';

export default function HomeSection() {
    const HomeImage = styled("div")(({ theme }) => ({
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            height: 650,
            width: "85%",
            display: "block",
            margin: "auto"
        }
    }))

    return (

        <div className="image-container">
            <HomeImage>
                <Box>
                    <img src={plans} alt="plans"></img>
                    <Grid className="image-text" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Grid item md={12}  ><h4 className="larger-text" >Trade Grinds</h4>
                            <p className='disclaimer-text'>An independent training company with no affiliation to any educational body</p>
                            <p className="smaller-text"> Practical Solutions for the Modern day Apprentice</p></Grid>
                    </Grid>
                    {/* <Grid item md={12} className="image-band" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Grid className="image-band-lower">
                            <img src={ATU} alt="atu" />
                            <img src={ETB} alt="etb" />
                            <img src={GE} alt="ge" />
                            <img src={KC} alt="kc" />
                            <img src={Solas} alt="solas" />
                            <img src={TU} alt="tu" />
                        </Grid>
                    </Grid> */}
                </Box>
            </HomeImage>

        </div>)
}