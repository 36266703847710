import './style.css'
import Forum from "./Components/Forum"
import React from "react";
import HowTo from "./Components/HowTo"
import HomeSection from "./Components/HomeSection"
import CardInfo1 from "./Components/CardInfo1"
import CardInfo2 from "./Components/CardInfo2"



function Home() {

    return (<div sx={{ marginTop: { xs: '55px', sm: '0px' } }} >
        <HomeSection />
        <CardInfo1/>
        <CardInfo2/>
        <HowTo />
        <Forum />
    </div >)
}

export default Home