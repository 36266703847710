import React from "react"
import { Outlet, Navigate } from "react-router-dom"
import { auth } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import LinearProgress from '@mui/material/LinearProgress';

const  PrivateRoutes = () => {
    const [user,loading] = useAuthState(auth);
    console.log(loading)
    if(!loading) {return (
        user ? <Outlet/> : <Navigate to="/login"/>
    )} 
    return (
    <div>
        .
    <LinearProgress/>
    </div>
    )   
}

export default PrivateRoutes