import { Card, CardContent, Box, Grid, Typography } from "@mui/material";
import style from './style.css'
import exam from "../../assets/undraw_online_test_re_kyfx.svg"
import React from 'react';
import { styled } from '@mui/material/styles';
import qr from "../../assets/qr-code.png"
import mobile1 from "../../assets/Mobile Shot 1 .jpg"
import mobile2 from "../../assets/Mobile Shot 2 .jpg"
import mobile3 from "../../assets/Mobile Image 3 .jpg"
import mail from "../../assets/undraw_mail_re_duel.svg"


const HomeImage = styled("div")(({ theme }) => ({
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
        height: 400,
        width: "85%",
        display: "block",
        margin: "auto"
    }
}))

function HowItWorks() {
    return (
        <div sx={{ marginTop: { xs: '60px' } }} className={style}>
            <Box style={{maxHeight:"350px"}}  sx={{ marginBottom: { xs: '0px',sm:"70px" }}}>
                <HomeImage style={{ backgroundColor: "white" }} sx={{ marginTop: { xs: '0px', sm: '70px' } , marginBottom:{sm:"80px"} }}>
                    <Box >
                        <img style={{maxHeight:"280px"}}  src={exam} alt="exam"></img>
                        How it works
                    </Box>
                </HomeImage>
            </Box>

            <Card style={{ marginTop: "20px" }} className="card">
                <CardContent>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item sm={12} md={6}  style={{marginRight:"25px"}}>
                            <img alt="qr-code" src={qr} />
                        </Grid>
                        <Grid item xs>
                            <Typography><b>Step 1:</b>  Scan the QR code or click the link <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=rT-HGnHNOkqxWQc5P3fSFnHJiKC2RJBJpZ8cBKzg5fFUQ0w2S0xXMFU4SkRZNjlKVVgzVFhUS0NLMS4u ">HERE</a></Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card className="card">
                <CardContent>
                    <Grid container spacing={3} alignItems="center">

                        <Grid item sm={12} md={6} order={{ xs: 2, md: 1 }}>

                            <Typography>
                                <b>Step 2:</b> Complete the 20 questions
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={6} order={{ xs: 1, md: 2 }}>

                            <img alt="qr-code" src={mobile1} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card style={{ marginTop: "20px" }} className="card">
                <CardContent>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item sm={12} md={6} >

                            <img alt="mobile2" src={mobile2} style={{maxWidth:"250px", marginLeft:"20px"}}/>
                        </Grid>

                        <Grid item xs>

                            <Typography><b>Step 3: </b>If you are unsure of the answer just take a guess and move on to the next question!</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card className="card">
                <CardContent>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item sm={12} md={6} order={{ xs: 2, md: 1 }}>
                            <Typography><span><b>Step 4: </b></span>Press Submit</Typography>
                        </Grid>
                        <Grid item sm={12} md={6} order={{ xs: 1, md: 2 }}>
                            <img alt="mobile2" src={mobile3} ></img>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card style={{ marginTop: "20px" }} className="card">
                <CardContent >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item sm={12} md={6}>
                            <img alt="email" src={mail}  style={{maxHeight:"450px"}}/>
                        </Grid>
                        <Grid item xs>
                            <Typography><b>Step 5: </b>If you want your RESULT email us at 
                             <a style={{marginLeft:"1em"}} href = "mailto: Tradegrinds2022@gmail.com">Tradegrinds2022@gmail.com</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        </div>)
}
export default HowItWorks