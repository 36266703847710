//jsx material ui component for the "About Us" page with cards for 5 sections
import { Card, Typography, CardContent, CardHeader, Grid, Box ,Link} from "@mui/material";
import us from '../../assets/undraw_team_page_re_cffb.svg'
import React  from 'react';


function AboutUs() {

  return (
    <div style={{ marginTop: "20px" }}>

      <Box
        component="img"
        sx={{
          height: 350,
          backgroundColor:"white",
        }}
        alt="The house from the offer."
        src={us}
      />
      <Grid container >
        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ width: "85%" }}>

            <CardContent>
              <CardHeader title={"About us"} style={{ marginTop: "40px", textAlign: "left" }}>
                About us
              </CardHeader>
              <Grid container justify="space-between">  
              <Typography align="left">
                All of our Tutors / Instructors are registered with the Teaching Council of Ireland and are Garda Vetted.

                Our Tutors are not only educators but Top coaches also. A good Coach cares about all aspects of the game and the player. An exceptional Coach will delve that bit deeper and mentally prepare you for any event.
                We will diligently prepare you to pass your exams at any phase of your college placement if you are prepared to work hard and have the right attitude.

                Maths is an extremely important subject particularly with the electrical trade and Instrumentation.Our Tutors will provide you with a discreet one on one service and build up your confidence over time thus giving you a passion for "Practical Maths".
                As a pupil in school you may have always struggled with maths due to external factors and not believing in yourself. The power is in your hands now as you take various steps on getting your Level 6 national craft certificate and become a top tradesman.
                Take the first step and reach out to us.

                Click below and complete the form and one of our administration crew will be in contact with you.
             
              </Typography>
              <Typography mt={2} mx={"auto"}>
              <Link href="/home">REGISTER TODAY</Link>
              </Typography>
              </Grid>
            </CardContent>

          </Card>
        </Grid>
      </Grid>


    </div>)

}

export default AboutUs
